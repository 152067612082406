import { createPinia } from 'pinia'
import { createApp } from 'vue'
import InstantSearch from 'vue-instantsearch/vue3/es'
import VueObserveVisibility from 'vue-observe-visibility'
import { Router } from 'vue-router'

import { iamPredicates } from '@ankor-io/common/auth/client/predicates'
import { sentryVueInit } from '@ankor-io/sentry-conflux/src/sentry'

import App from '@/App.vue'
import { apolloClientInstance } from '@/apollo/apolloClient'
import AppV2 from '@/app/AppV2.vue'
import routerV2 from '@/app/routerV2'
import { fetchConfig } from '@/config/fetch'
import { useDiffSyncPlugin } from '@/diffsync/plugin'
import { createAuthentication } from '@/iam/authentication'
import createRouter from '@/router'
import { createSearchClient } from '@/search/client'
import { growthbookInstance } from '@/utils/growthbook'
import { initializeGtm } from '@/utils/gtm'

// First load the config
const config = await fetchConfig()
// Get the authentication context
const isRegisterPath = window.location.pathname === '/register'

const authenticationContext = await createAuthentication(config.AUTHENTICATION_CLIENT_OPTIONS, isRegisterPath)

/**
 * Track application errors
 */
window.onerror = function (msg, _url, _line, _col, error) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: msg,
    stack: error?.stack,
  })
  console.log(error)
}

/**
 * Track application promises rejections
 */
window.addEventListener('unhandledrejection', function (event) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: event.reason.message,
    stack: event.reason.stack,
  })
  console.log(event)
})

// As part of a refresh App.vue, based on the /trips route load the new App.vue
const path = window.location.pathname
if (path.startsWith('/trips')) {
  const app = createApp(AppV2)

  // Install the navigation guards
  routerV2.beforeEach(async (to) => {
    return await iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to)
  })

  app.use(config)
  app.use(routerV2)
  app.use(authenticationContext)
  app.use(VueObserveVisibility)
  app.use(InstantSearch)

  const growthbook = await growthbookInstance.createGrowthbookClient(
    {
      clientKey: config.GROWTHBOOK.clientKey,
      enableDevMode: true,
    },
    authenticationContext,
  )
  app.use(growthbook)

  app.mount('#app')
  app.config.globalProperties.window = window
} else {
  const app = sentryVueInit(createApp(App), config.SENTRY)

  // Create the router
  const router: Router = createRouter(config)

  // Install the navigation guards
  router.beforeEach(async (to) => {
    return await iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to)
  })

  app.use(config)
  app.use(router)
  app.use(useDiffSyncPlugin({ wsUrl: config.WS_URL }))
  app.use(initializeGtm(config, router))
  app.use(authenticationContext)

  // Get a token for one-time-use mechanisms, e.g. build search key.
  const _token = await authenticationContext.getToken()
  if (!_token) {
    authenticationContext.redirectToLogin()
  }

  const organizations = await authenticationContext.getUserOrganizations()

  if (
    config.AUTHENTICATION_CLIENT_OPTIONS?.default_org_code &&
    !organizations.orgCodes.includes(config.AUTHENTICATION_CLIENT_OPTIONS.default_org_code)
  ) {
    // Add the user to the default organization if not already added
    await fetch(`/api/iam/organization/${config.AUTHENTICATION_CLIENT_OPTIONS.default_org_code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${_token}`,
      },
    })
  }

  const growthbook = await growthbookInstance.createGrowthbookClient(
    {
      clientKey: config.GROWTHBOOK.clientKey,
      enableDevMode: true,
    },
    authenticationContext,
  )
  app.use(growthbook)
  //
  try {
    const searchClient = await createSearchClient(_token, config.ALGOLIA.app_id)
    app.use(searchClient)
  } catch (error) {
    authenticationContext.redirectToLogin()
  }

  //
  app.use(VueObserveVisibility)

  app.use(apolloClientInstance.createApolloClient(authenticationContext))

  const pinia = createPinia()
  app.use(pinia)

  /**
   * Track vue application errors
   */
  app.config.errorHandler = (err: any, vm: any, info: any) => {
    //@ts-ignore
    window.heap?.track('applicationError', {
      error: err.message,
      stack: err.stack,
      info: info,
      vm: vm,
    })
    console.log(err)
  }

  app.mount('#app')
}
